import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_transload_order_lines_gridComponent } from './FootPrintManager.transload_order_lines_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { TransloadOrders_orderline_total_packaged_amount_widgetComponent } from './TransloadOrders.orderline_total_packaged_amount_widget.component';
import { TransloadOrders_orderline_total_received_widgetComponent } from './TransloadOrders.orderline_total_received_widget.component';
import { TransloadOrders_orderline_total_gross_received_widgetComponent } from './TransloadOrders.orderline_total_gross_received_widget.component';
import { TransloadOrders_orderclasses_dd_singleComponent } from './TransloadOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { TransloadOrders_conveyances_dd_singleComponent } from './TransloadOrders.conveyances_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_transload_order_lines_gridComponent),
    forwardRef(() => FootPrintManager_orderaddresses_gridComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => TransloadOrders_orderline_total_packaged_amount_widgetComponent),
    forwardRef(() => TransloadOrders_orderline_total_received_widgetComponent),
    forwardRef(() => TransloadOrders_orderline_total_gross_received_widgetComponent),
    forwardRef(() => TransloadOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => TransloadOrders_conveyances_dd_singleComponent),
  ],
  selector: 'FootPrintManager-transload_order_editor',
  templateUrl: './FootPrintManager.transload_order_editor.component.html'
})
export class FootPrintManager_transload_order_editorComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { inboundShipmentId?: number, billingAddressId?: number, shipTo?: string, outboundShipmentId?: number } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, BillOfLading?: string, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, ExpectedWarehouseId?: number, Notes?: string, ReferenceNumber?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, TransLoadsForInboundOrder?: { Id?: number, OutboundOrder?: { Id?: number, OrderStatusId?: number, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ConveyanceId?: number, ConveyenceReference?: string } }[] } }[] };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    bill_of_lading: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    conveyance_inbound: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    conveyance_reference_inbound: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    conveyance_outbound: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    conveyance_reference_outbound: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process_complete: new ToolModel(new ButtonModel('process_complete', new ButtonStyles(null, null), false, 'Process & Complete', 'ms-Icon ms-Icon--Process')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, 'Cancel', 'ms-Icon ms-Icon--Blocked')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    ),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, 'Print', 'ms-Icon ms-Icon--Print')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lookupcode', true)
,
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order Class', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    order_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order Status', false)
,
    bill_of_lading: new FieldModel(new TextBoxModel(this.formGroup.controls['bill_of_lading'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'BOL', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'PO/ Reference', false)
,
    conveyance_inbound: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['conveyance_inbound'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Conveyance Inbound', true)
,
    conveyance_reference_inbound: new FieldModel(new TextBoxModel(this.formGroup.controls['conveyance_reference_inbound'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Conveyance Ref Inbound', true)
,
    conveyance_outbound: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['conveyance_outbound'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Conveyance Outbound', true)
,
    conveyance_reference_outbound: new FieldModel(new TextBoxModel(this.formGroup.controls['conveyance_reference_outbound'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Conveyance Ref Outbound', true)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      purchase_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          ),
        }
        ),
    };
  
    //#region tabs inParams
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.Id, $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.Id];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId, $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_purchase_order_lines', { read: FootPrintManager_transload_order_lines_gridComponent }) $tabs_purchase_order_lines: FootPrintManager_transload_order_lines_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_orderaddresses_gridComponent }) $tabs_contacts: FootPrintManager_orderaddresses_gridComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
    //#endregion tabs children
    widgets = {
      orderline_total_packaged_amount_widget: new WidgetModel(),
      orderline_total_received_widget: new WidgetModel(),
      orderline_total_gross_received_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_orderline_total_packaged_amount_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_gross_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_orderline_total_packaged_amount_widget', { read:  TransloadOrders_orderline_total_packaged_amount_widgetComponent }) $widgets_orderline_total_packaged_amount_widget: TransloadOrders_orderline_total_packaged_amount_widgetComponent;
      @ViewChild('$widgets_orderline_total_received_widget', { read:  TransloadOrders_orderline_total_received_widgetComponent }) $widgets_orderline_total_received_widget: TransloadOrders_orderline_total_received_widgetComponent;
      @ViewChild('$widgets_orderline_total_gross_received_widget', { read:  TransloadOrders_orderline_total_gross_received_widgetComponent }) $widgets_orderline_total_gross_received_widget: TransloadOrders_orderline_total_gross_received_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    bill_of_lading: this.fields.bill_of_lading.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    conveyance_inbound: this.fields.conveyance_inbound.control.valueChanges
    ,
    conveyance_reference_inbound: this.fields.conveyance_reference_inbound.control.valueChanges
    ,
    conveyance_outbound: this.fields.conveyance_outbound.control.valueChanges
    ,
    conveyance_reference_outbound: this.fields.conveyance_reference_outbound.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) { 
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.purchase_order_lines,
      this.tabs.contacts,
      this.tabs.billing,
    ]; 
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Transload Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.FootPrintManager.ds_transload_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($utils.isDefined($editor.entity.PreferredWarehouseId) ? $editor.entity.PreferredWarehouseId : $editor.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.bill_of_lading.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.BillOfLading);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.Notes);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ReferenceNumber);
    (this.fields.conveyance_inbound.control as SelectBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ConveyanceId);
    (this.fields.conveyance_reference_inbound.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ConveyenceReference);
    (this.fields.conveyance_outbound.control as SelectBoxModel).reset($editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.ShipmentOrderLookups[0]?.Shipment?.ConveyanceId);
    (this.fields.conveyance_reference_outbound.control as TextBoxModel).reset($editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.ShipmentOrderLookups[0]?.Shipment?.ConveyenceReference);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_orderline_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_orderline_total_packaged_amount_widget) && !this.widgets.orderline_total_packaged_amount_widget.hidden) {
        this.$widgets_orderline_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_received_widget') {
      if (!isNil(this.$widgets_orderline_total_received_widget) && !this.widgets.orderline_total_received_widget.hidden) {
        this.$widgets_orderline_total_received_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_gross_received_widget') {
      if (!isNil(this.$widgets_orderline_total_gross_received_widget) && !this.widgets.orderline_total_gross_received_widget.hidden) {
        this.$widgets_orderline_total_gross_received_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_purchase_order_lines') {
        if (!isNil(this.$tabs_purchase_order_lines) && !this.tabs.purchase_order_lines.hidden) {
          this.$tabs_purchase_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .bill_of_lading
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .conveyance_inbound
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .conveyance_reference_inbound
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .conveyance_outbound
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .conveyance_reference_outbound
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
   
   const result = await $flows.TransloadOrders.is_transload_order_deletable_flow({ transloadId: transloadId });
   const reason = result.reason;
   if ($utils.isDefined(reason)) {
       await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
       return;
   } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete order', `Are you sure you want to delete order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.TransloadOrders.delete_transload_order_flow({ transloadId: transloadId});
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
                  return;
              }
              else {
                  const title = 'Delete Order';
                  const message = `Order ${$editor.entity.LookupCode} deleted.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.close();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Delete order error', 'An error occurred during deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
   
   const result = await $flows.TransloadOrders.is_transload_order_cancelable_flow({ transloadId: transloadId });
   const reason = result.reason;
   if ($utils.isDefined(reason)) {
       await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', reason);
       return;
   } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.TransloadOrders.cancel_transload_order_flow({ transloadId: transloadId});
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', reason);
                  return;
              }
              else {
                  const title = 'Cancel Order';
                  const message = `Order ${$editor.entity.LookupCode} cancelled.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Cancel order error', 'An error occurred during cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set editor title
  $editor.title = `Order ${$editor.entity.LookupCode}`;
  
  
  // Set ship from read only text
  $editor.vars.shipTo = $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.FirstName + ' ' +
      $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.LastName + ' ' +
      $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line1 + ' ' +
      ($utils.isDefined($editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line2) ?
          ', ' + $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line2 : ' ') +
      ($utils.isDefined($editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.City) ?
          ', ' + $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.City : ' ') +
      ($utils.isDefined($editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.State) ?
          $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.State + ' ' : ' ') +
      ($utils.isDefined($editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.PostalCode) ?
          $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.PostalCode + ' ' : ' ') +
      ($utils.isDefined($editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Country) ?
          $editor.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Country : ' ');
  
  
  
  // Set outbound shipment var
  const outboundShipment = $editor.entity.TransLoadsForInboundOrder[0].OutboundOrder.ShipmentOrderLookups[0].ShipmentId;
  if ($utils.isDefined(outboundShipment)) {
      $editor.vars.outboundShipmentId = outboundShipment;
  
  }
  
  // Fetch inbound shipments
  const shipments = (await $datasources.TransloadOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.TransloadOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  else if (!$utils.isDefined(shipments)) {
      // close
      await $shell.TransloadOrders.openErrorDialog('Invalid order', 'This order does not have a shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.vars.inboundShipmentId = shipments[0].ShipmentId;
          $editor.fields.warehouse.required = true;
  
      }
  
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      const inboundOrderStatus = $editor.entity.OrderStatusId;
      const outboundOrderStatus = $editor.entity.TransLoadsForInboundOrder[0].OutboundOrder.OrderStatusId;
  
      // created status
      if (inboundOrderStatus === 1) {
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.cancel.control.readOnly = false;
          $editor.toolbar.on_delete.hidden = false;
          $editor.toolbar.on_delete.control.readOnly = false;
          $editor.toolbar.process_complete.hidden = false;
          // disable process if no warehouse
          $editor.toolbar.process_complete.control.readOnly = noWarehouse;
  
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = false;
          $editor.fields.bill_of_lading.control.readOnly = false;
          $editor.fields.notes.control.readOnly = false;
          $editor.fields.reference.control.readOnly = false;
          $editor.fields.conveyance_inbound.control.readOnly = false;
          $editor.fields.conveyance_reference_inbound.control.readOnly = false;
          $editor.fields.conveyance_outbound.control.readOnly = false;
          $editor.fields.conveyance_reference_outbound.control.readOnly = false;
  
          // Widgets
           $editor.widgets.orderline_total_gross_received_widget.hidden = true;
           $editor.widgets.orderline_total_received_widget.hidden = true;
  
  
  
      } else if (inboundOrderStatus === 2 || outboundOrderStatus === 2) {
          // processing status
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.bill_of_lading.control.readOnly = false;
          $editor.fields.notes.control.readOnly = false;
          $editor.fields.reference.control.readOnly = false;
          $editor.fields.conveyance_inbound.control.readOnly = false;
          $editor.fields.conveyance_reference_inbound.control.readOnly = false;
          $editor.fields.conveyance_outbound.control.readOnly = false;
          $editor.fields.conveyance_reference_outbound.control.readOnly = false;
  
          // Set custom status
          let transload_state = await $flows.TransloadOrders.get_transload_order_state({ transloadId: $editor.entity.TransLoadsForInboundOrder[0].Id });
  
          switch (transload_state.state) {
  
              case 2: {
                  $editor.fields.order_status.control.text = 'Ready to receive'
                  $editor.toolbar.cancel.hidden = false;
                  $editor.toolbar.cancel.control.readOnly = false;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process_complete.control.readOnly = false;
                  break;
              }
              case 3: {
                  $editor.fields.order_status.control.text = 'Ready to complete inbound'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process_complete.control.readOnly = false;
                  break;
              }
              case 4: {
                  $editor.fields.order_status.control.text = 'Ready to pick'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process_complete.control.readOnly = false;
                  break;
              }
              case 5: {
                  $editor.fields.order_status.control.text = 'Ready to load'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process_complete.control.readOnly = false;
                  break;
              }
              case 6: {
                  $editor.fields.order_status.control.text = 'Ready to complete outbound'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process_complete.control.readOnly = false;
                  break;
              }
  
          }
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
  
  
      }
      // Inbound and outbound completed or inbound and outbound cancelled
      else if ((inboundOrderStatus === 4 && outboundOrderStatus === 4) || (inboundOrderStatus === 8 && outboundOrderStatus === 8)) {
          $editor.toolbar.process_complete.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.bill_of_lading.control.readOnly = true;
          $editor.fields.notes.control.readOnly = true;
          $editor.fields.reference.control.readOnly = true;
          $editor.fields.conveyance_inbound.control.readOnly = true;
          $editor.fields.conveyance_reference_inbound.control.readOnly = true;
          $editor.fields.conveyance_outbound.control.readOnly = true;
          $editor.fields.conveyance_reference_outbound.control.readOnly = true;
  
          // Widgets
           $editor.widgets.orderline_total_gross_received_widget.hidden = false;
           $editor.widgets.orderline_total_received_widget.hidden = false;
  
  
  
  
      }
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Set Date/Time Formats
  const date_format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_process_complete_clicked(event = null) {
    return this.on_process_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_complete_clickedInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const orderlines = (await $datasources.PurchaseOrders.ds_get_orderLines_by_orderId.get({ orderId: $editor.entity.Id })).result;
  if (!$utils.isDefined(orderlines)) {
      throw new Error('Order must have at least one order line to process.')
  }
  
  
  var transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
  
  //const result = await $flows.TransloadOrders.is_transload_order_processable_flow({ transloadId: transloadId });
  const reason = null// result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Process Order', `Are you sure you want to process and fully complete the order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              $editor.toolbar.process_complete.control.readOnly = true;
              $editor.toolbar.process_complete.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
              $editor.toolbar.cancel.control.readOnly = true;
              $editor.toolbar.on_delete.control.readOnly = true;
  
              for (let i = 0; i < 10; i++) {
                  let transload_state = await $flows.TransloadOrders.get_transload_order_state({ transloadId: transloadId });
                  if (transload_state.state < 7) {
  
                      switch (transload_state.state) {
                          case 1: {
                              $editor.toolbar.process_complete.control.label = 'Processing'
                              const result = await $flows.TransloadOrders.process_transload_order_flow({ transloadId: transloadId });
                              const reason = result.reason;
  
                              if ($utils.isDefined(reason)) {
                                  await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', reason);
                                  $editor.toolbar.process_complete.control.styles.resetStyle();
                                  $editor.toolbar.process_complete.control.icon = null;
                                  $editor.toolbar.process_complete.control.label = 'Process & Complete';
                                  $editor.toolbar.process_complete.control.readOnly = false;
                                  $editor.refresh();
  
                                  return;
                              }
                              else {
                                  break;
                              }
                          }
                          case 2: {
  
                              $editor.toolbar.process_complete.control.label = 'Receiving'
                              const result = await $flows.TransloadOrders.receive_transload_order_flow({ transloadId: transloadId });
                              const reason = result.reason;
                              if ($utils.isDefined(reason)) {
                                  await $shell.FootPrintManager.openErrorDialog('Order cannot be received.', reason);
                                  $editor.toolbar.process_complete.control.styles.resetStyle();
                                  $editor.toolbar.process_complete.control.icon = null;
                                  $editor.toolbar.process_complete.control.label = 'Process & Complete';
                                  $editor.toolbar.process_complete.control.readOnly = false;
                                  $editor.refresh();
  
                                  return;
                              }
                              else {
                                  break;
                              }
  
                          }
                          case 3: {
  
                              $editor.toolbar.process_complete.control.label = 'Completing Inbound'
                              const result = await $flows.TransloadOrders.complete_inbound_transload_order_flow({ transloadId: transloadId });
                              const reason = result.reason;
                              if ($utils.isDefined(reason)) {
                                  await $shell.FootPrintManager.openErrorDialog('Inbound Order cannot be completed.', reason);
                                  $editor.toolbar.process_complete.control.styles.resetStyle();
                                  $editor.toolbar.process_complete.control.icon = null;
                                  $editor.toolbar.process_complete.control.label = 'Process & Complete';
                                  $editor.toolbar.process_complete.control.readOnly = false;
                                  $editor.refresh();
  
                                  return;
                              }
                              else {
                                  break;
                              }
                          }
                          case 4: {
  
                              $editor.toolbar.process_complete.control.label = 'Picking'
                              const result = await $flows.TransloadOrders.pick_transload_order_flow({ transloadId: transloadId });
                              const reason = result.reason;
                              if ($utils.isDefined(reason)) {
                                  await $shell.FootPrintManager.openErrorDialog('Order cannot be picked.', reason);
                                  $editor.toolbar.process_complete.control.styles.resetStyle();
                                  $editor.toolbar.process_complete.control.icon = null;
                                  $editor.toolbar.process_complete.control.label = 'Process & Complete';
                                  $editor.toolbar.process_complete.control.readOnly = false;
                                  $editor.refresh();
  
                                  return;
                              }
                              else {
                                  break;
                              }
                          }
                          case 5: {
  
                              $editor.toolbar.process_complete.control.label = 'Loading'
                              const result = await $flows.TransloadOrders.load_transload_order_flow({ transloadId: transloadId });
                              const reason = result.reason;
                              if ($utils.isDefined(reason)) {
                                  await $shell.FootPrintManager.openErrorDialog('Order cannot be loaded.', reason);
                                  $editor.toolbar.process_complete.control.styles.resetStyle();
                                  $editor.toolbar.process_complete.control.icon = null;
                                  $editor.toolbar.process_complete.control.label = 'Process & Complete';
                                  $editor.toolbar.process_complete.control.readOnly = false;
                                  $editor.refresh();
  
                                  return;
                              }
                              else {
                                  break;
                              }
                          }
                          case 6: {
  
                              $editor.toolbar.process_complete.control.label = 'Completing Outbound'
                              const result = await $flows.TransloadOrders.complete_outbound_transload_order_flow({ transloadId: transloadId });
                              const reason = result.reason;
                              if ($utils.isDefined(reason)) {
                                  await $shell.FootPrintManager.openErrorDialog('Outbound Order cannot be completed.', reason);
                                  $editor.toolbar.process_complete.control.styles.resetStyle();
                                  $editor.toolbar.process_complete.control.icon = null;
                                  $editor.toolbar.process_complete.control.label = 'Process & Complete';
                                  $editor.toolbar.process_complete.control.readOnly = false;
                                  $editor.refresh();
  
                                  return;
                              } else {
                                  break;
                              }
                          }
  
                      }
  
                  } else {
                      break;
                  }
  
              }
              $editor.toolbar.process_complete.control.styles.resetStyle();
              $editor.toolbar.process_complete.control.icon = null;
              $editor.toolbar.process_complete.control.label = 'Process & Complete';
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be processed.', reason);
                  $editor.refresh();
                  
                  return;
              }
              else {
                  const title = 'Process Order';
                  const message = `Order ${$editor.entity.LookupCode} processed and completed.`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
          } catch (error) {
              $editor.toolbar.process_complete.control.styles.resetStyle();
              $editor.toolbar.process_complete.control.icon = null;
              $editor.toolbar.process_complete.control.label = 'Process & Complete';
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order processing error', 'An error occurred during processing of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
      
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  const inboundShipmentExist = $utils.isDefined($editor.vars.inboundShipmentId);
  const outboundShipmentExist = $utils.isDefined($editor.vars.outboundShipmentId);
  const outboundOrderIdExist = $utils.isDefined($editor.entity.TransLoadsForInboundOrder[0].OutboundOrder.Id)
  
  
  // Inbound Order
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
  }
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  if (outboundOrderIdExist) {
      // Outbound Order
      let outboundOrderpayload: any = {};
      if ($editor.fields.project.control.isChanged) {
          outboundOrderpayload.ProjectId = $editor.fields.project.control.value;
      }
      if ($editor.fields.lookupcode.control.isChanged) {
          outboundOrderpayload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.reference.control.isChanged) {
          outboundOrderpayload.OwnerReference = $editor.fields.reference.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          outboundOrderpayload.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          outboundOrderpayload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.TransLoadsForInboundOrder[0].OutboundOrder.Id, entity: payload });
  }
  
  
  if (inboundShipmentExist) {
      // Inbound Shipment
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
      }
      if ($editor.fields.conveyance_inbound.control.isChanged) {
          payload.ConveyanceId = $editor.fields.conveyance_inbound.control.value;
      }
      if ($editor.fields.conveyance_reference_inbound.control.isChanged) {
          payload.ConveyenceReference = $editor.fields.conveyance_reference_inbound.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.reference.control.isChanged) {
          payload.ReferenceNumber = $editor.fields.reference.control.value;
      }
      if ($editor.fields.bill_of_lading.control.isChanged) {
          payload.BillOfLading = $editor.fields.bill_of_lading.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: $editor.vars.inboundShipmentId, entity: payload });
  
  
  }
  
  if (outboundShipmentExist) {
      // Outbound Shipment
      let outboundShipmentPayload: any = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          outboundShipmentPayload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          outboundShipmentPayload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
      }
      if ($editor.fields.conveyance_outbound.control.isChanged) {
          outboundShipmentPayload.ConveyanceId = $editor.fields.conveyance_outbound.control.value;
      }
      if ($editor.fields.conveyance_reference_outbound.control.isChanged) {
          outboundShipmentPayload.ConveyenceReference = $editor.fields.conveyance_reference_outbound.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          outboundShipmentPayload.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.reference.control.isChanged) {
          outboundShipmentPayload.ReferenceNumber = $editor.fields.reference.control.value;
      }
      if ($editor.fields.bill_of_lading.control.isChanged) {
          outboundShipmentPayload.BillOfLading = $editor.fields.bill_of_lading.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: $editor.vars.outboundShipmentId, entity: outboundShipmentPayload });
  
  
  }
  
  await $editor.refresh();
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_transload_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  
  
  
  await $shell.PurchaseOrders.openreceiving_reportDialog({ orderId: $editor.inParams.orderId });
  }
  //#endregion private flows
}
