<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-process" *ngIf="!toolbar.process.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.process.control.readOnly"
              [ngStyle]="toolbar.process.control.styles.style"
              [ngClass]="toolbar.process.control.styles.classes"
              (click)="on_process_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.process.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.process.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.process.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
      >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-delete_transfer" *ngIf="!toolbar.delete_transfer.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.delete_transfer.control.readOnly"
              [ngStyle]="toolbar.delete_transfer.control.styles.style"
              [ngClass]="toolbar.delete_transfer.control.styles.classes"
      >
        <div class="button-label">
          <ng-container *ngIf="toolbar.delete_transfer.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.delete_transfer.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.delete_transfer.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.status.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-source"
                     *ngIf="!fieldsets.source.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.source.collapsible }">
                    <div *ngIf="!fieldsets.source.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.source.toggle()">
                      <span class="fieldsetsTitle-text">Source</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.source.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.source.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.source.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.source.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-source_owner" *ngIf="!fields.source_owner.hidden" 
                            class="field-container standard {{fields.source_owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.source_owner.styles.style"
                            [ngClass]="fields.source_owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.source_owner.label}}{{fields.source_owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.source_owner.label}}">{{fields.source_owner.label}}<span *ngIf="fields.source_owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.source_owner.control.type"
                            formControlName="source_owner"
                            (displayTextChange)="fields.source_owner.control.displayText=$event"
                            [placeholder]="fields.source_owner.control.placeholder"
                            [styles]="fields.source_owner.control.styles"
                          [statusId]="$fields_source_owner_selector_inParams_statusId"
                          [projectId]="$fields_source_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.source_owner.invalid">
                          <ng-container *ngFor="let error of fields.source_owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_project" *ngIf="!fields.source_project.hidden" 
                            class="field-container standard {{fields.source_project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.source_project.styles.style"
                            [ngClass]="fields.source_project.styles.classes">
                        <div class="label-container"
                              title="{{fields.source_project.label}}{{fields.source_project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.source_project.label}}">{{fields.source_project.label}}<span *ngIf="fields.source_project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.source_project.control.type"
                            formControlName="source_project"
                            (displayTextChange)="fields.source_project.control.displayText=$event"
                            [placeholder]="fields.source_project.control.placeholder"
                            [styles]="fields.source_project.control.styles"
                          [statusId]="$fields_source_project_selector_inParams_statusId"
                          [ownerId]="$fields_source_project_selector_inParams_ownerId"
                          [excludedProjectIds]="$fields_source_project_selector_inParams_excludedProjectIds"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.source_project.invalid">
                          <ng-container *ngFor="let error of fields.source_project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-target"
                     *ngIf="!fieldsets.target.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.target.collapsible }">
                    <div *ngIf="!fieldsets.target.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.target.toggle()">
                      <span class="fieldsetsTitle-text">Target</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.target.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.target.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.target.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.target.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-target_owner" *ngIf="!fields.target_owner.hidden" 
                            class="field-container standard {{fields.target_owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.target_owner.styles.style"
                            [ngClass]="fields.target_owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.target_owner.label}}{{fields.target_owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.target_owner.label}}">{{fields.target_owner.label}}<span *ngIf="fields.target_owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.target_owner.control.type"
                            formControlName="target_owner"
                            (displayTextChange)="fields.target_owner.control.displayText=$event"
                            [placeholder]="fields.target_owner.control.placeholder"
                            [styles]="fields.target_owner.control.styles"
                          [statusId]="$fields_target_owner_selector_inParams_statusId"
                          [projectId]="$fields_target_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.target_owner.invalid">
                          <ng-container *ngFor="let error of fields.target_owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-target_project" *ngIf="!fields.target_project.hidden" 
                            class="field-container standard {{fields.target_project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.target_project.styles.style"
                            [ngClass]="fields.target_project.styles.classes">
                        <div class="label-container"
                              title="{{fields.target_project.label}}{{fields.target_project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.target_project.label}}">{{fields.target_project.label}}<span *ngIf="fields.target_project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.target_project.control.type"
                            formControlName="target_project"
                            (displayTextChange)="fields.target_project.control.displayText=$event"
                            [placeholder]="fields.target_project.control.placeholder"
                            [styles]="fields.target_project.control.styles"
                          [statusId]="$fields_target_project_selector_inParams_statusId"
                          [ownerId]="$fields_target_project_selector_inParams_ownerId"
                          [excludedProjectIds]="$fields_target_project_selector_inParams_excludedProjectIds"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.target_project.invalid">
                          <ng-container *ngFor="let error of fields.target_project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.lines.hidden" class="tab" data-cy="tab-lines">
              <h2 [className]="tabs.lines.active? 'active': ''" (click)="tabs.lines.activate()">{{tabs.lines.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_transfer_lines_grid *ngIf="tabs.lines.active"
              #$tabs_lines
              [inventoryTransferId]="$tabs_lines_inventory_transfer_lines_grid_inParams_inventoryTransferId"
              [sourceProjectId]="$tabs_lines_inventory_transfer_lines_grid_inParams_sourceProjectId"
              [targetProjectId]="$tabs_lines_inventory_transfer_lines_grid_inParams_targetProjectId"
              [warehouseId]="$tabs_lines_inventory_transfer_lines_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_lines')">
              </FootPrintManager-inventory_transfer_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>