import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_custom_materials_udf_gridComponent } from './app.custom_materials_udf_grid.component';
import { app_custom_bill_of_lading_reportComponent } from './app.custom_bill_of_lading_report.component';
import { app_custom_invoice_reportComponent } from './app.custom_invoice_report.component';
import { app_custom_load_slip_reportComponent } from './app.custom_load_slip_report.component';
import { app_custom_pick_slip_reportComponent } from './app.custom_pick_slip_report.component';
import { app_custom_receiving_reportComponent } from './app.custom_receiving_report.component';
import { app_custom_unloading_tally_reportComponent } from './app.custom_unloading_tally_report.component';
import { app_custom_receive_hold_types_dd_singleComponent } from './app.custom_receive_hold_types_dd_single.component';
import { app_custom_receive_hold_types_dd_multiComponent } from './app.custom_receive_hold_types_dd_multi.component';

import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Utilities_ShellService } from './Utilities.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Utilities: Utilities_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public Invoices: Invoices_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_materials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Materials UDF',
        referenceName: 'custom_materials_udf_grid',
        component: app_custom_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_materials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_materials_udf_gridComponent,
      'Custom Materials UDF',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_bill_of_lading_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Bill of lading',
        referenceName: 'custom_bill_of_lading_report',
        component: app_custom_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_bill_of_lading_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_bill_of_lading_reportComponent,
      'Bill of lading',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_invoice_report(inParams:{ invoiceId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoice Report',
        referenceName: 'custom_invoice_report',
        component: app_custom_invoice_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_reportDialog(
    inParams:{ invoiceId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_invoice_reportComponent,
      'Invoice Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_load_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load Slip',
        referenceName: 'custom_load_slip_report',
        component: app_custom_load_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_load_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_load_slip_reportComponent,
      'Load Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'custom_pick_slip_report',
        referenceName: 'custom_pick_slip_report',
        component: app_custom_pick_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_pick_slip_reportComponent,
      'custom_pick_slip_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Receiving Report',
        referenceName: 'custom_receiving_report',
        component: app_custom_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_receiving_reportComponent,
      'Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_unloading_tally_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Unloading Tally Report',
        referenceName: 'custom_unloading_tally_report',
        component: app_custom_unloading_tally_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_unloading_tally_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_unloading_tally_reportComponent,
      'Unloading Tally Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'footprint_wms_home') {
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_materials_udf_grid') {
      const title = 'Custom Materials UDF';
      const component = app_custom_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_bill_of_lading_report') {
      const title = 'Bill of lading';
      const component = app_custom_bill_of_lading_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_report') {
      const title = 'Invoice Report';
      const component = app_custom_invoice_reportComponent;
      const inParams:{ invoiceId: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_load_slip_report') {
      const title = 'Load Slip';
      const component = app_custom_load_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_pick_slip_report') {
      const title = 'custom_pick_slip_report';
      const component = app_custom_pick_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_receiving_report') {
      const title = 'Receiving Report';
      const component = app_custom_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_unloading_tally_report') {
      const title = 'Unloading Tally Report';
      const component = app_custom_unloading_tally_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_receive_hold_types_dd_single') {
      const title = 'Receive Hold Types';
      const component = app_custom_receive_hold_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_receive_hold_types_dd_multi') {
      const title = 'Receive Hold Types';
      const component = app_custom_receive_hold_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
