<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-process_complete" *ngIf="!toolbar.process_complete.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.process_complete.control.readOnly"
              [ngStyle]="toolbar.process_complete.control.styles.style"
              [ngClass]="toolbar.process_complete.control.styles.classes"
              (click)="on_process_complete_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.process_complete.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.process_complete.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.process_complete.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-on_delete" *ngIf="!toolbar.on_delete.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.on_delete.control.readOnly"
              [ngStyle]="toolbar.on_delete.control.styles.style"
              [ngClass]="toolbar.on_delete.control.styles.classes"
              (click)="on_delete_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.on_delete.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.on_delete.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-print" *ngIf="!toolbar.print.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.print.control.readOnly"
              [ngStyle]="toolbar.print.control.styles.style"
              [ngClass]="toolbar.print.control.styles.classes"
              (click)="on_print_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.print.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.print.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.print.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Order</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_class.label}}{{fields.order_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_class.label}}">{{fields.order_class.label}}<span *ngIf="fields.order_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <TransloadOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                        >
                        </TransloadOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_status" *ngIf="!fields.order_status.hidden" 
                            class="field-container standard {{fields.order_status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_status.styles.style"
                            [ngClass]="fields.order_status.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_status.label}}{{fields.order_status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_status.label}}">{{fields.order_status.label}}<span *ngIf="fields.order_status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.order_status.control.styles.style"
                              [ngClass]="fields.order_status.control.styles.classes">{{fields.order_status.control.text }}</div>
                        <ng-container *ngIf="fields.order_status.invalid">
                          <ng-container *ngFor="let error of fields.order_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-bill_of_lading" *ngIf="!fields.bill_of_lading.hidden" 
                            class="field-container standard {{fields.bill_of_lading.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.bill_of_lading.styles.style"
                            [ngClass]="fields.bill_of_lading.styles.classes">
                        <div class="label-container"
                              title="{{fields.bill_of_lading.label}}{{fields.bill_of_lading.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.bill_of_lading.label}}">{{fields.bill_of_lading.label}}<span *ngIf="fields.bill_of_lading.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="bill_of_lading"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.bill_of_lading.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.bill_of_lading.control.placeholder}}"
                                [ngStyle]="fields.bill_of_lading.control.styles.style"
                                [ngClass]="fields.bill_of_lading.control.styles.classes"> 
                        <ng-container *ngIf="fields.bill_of_lading.invalid">
                          <ng-container *ngFor="let error of fields.bill_of_lading.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container standard {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.reference.label}}{{fields.reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reference.label}}">{{fields.reference.label}}<span *ngIf="fields.reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-conveyance_inbound" *ngIf="!fields.conveyance_inbound.hidden" 
                            class="field-container standard {{fields.conveyance_inbound.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.conveyance_inbound.styles.style"
                            [ngClass]="fields.conveyance_inbound.styles.classes">
                        <div class="label-container"
                              title="{{fields.conveyance_inbound.label}}{{fields.conveyance_inbound.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.conveyance_inbound.label}}">{{fields.conveyance_inbound.label}}<span *ngIf="fields.conveyance_inbound.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <TransloadOrders-conveyances_dd_single 
                            data-cy="selector"
                            [type]="fields.conveyance_inbound.control.type"
                            formControlName="conveyance_inbound"
                            (displayTextChange)="fields.conveyance_inbound.control.displayText=$event"
                            [placeholder]="fields.conveyance_inbound.control.placeholder"
                            [styles]="fields.conveyance_inbound.control.styles"
                        >
                        </TransloadOrders-conveyances_dd_single>
                        <ng-container *ngIf="fields.conveyance_inbound.invalid">
                          <ng-container *ngFor="let error of fields.conveyance_inbound.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-conveyance_reference_inbound" *ngIf="!fields.conveyance_reference_inbound.hidden" 
                            class="field-container standard {{fields.conveyance_reference_inbound.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.conveyance_reference_inbound.styles.style"
                            [ngClass]="fields.conveyance_reference_inbound.styles.classes">
                        <div class="label-container"
                              title="{{fields.conveyance_reference_inbound.label}}{{fields.conveyance_reference_inbound.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.conveyance_reference_inbound.label}}">{{fields.conveyance_reference_inbound.label}}<span *ngIf="fields.conveyance_reference_inbound.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="conveyance_reference_inbound"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.conveyance_reference_inbound.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.conveyance_reference_inbound.control.placeholder}}"
                                [ngStyle]="fields.conveyance_reference_inbound.control.styles.style"
                                [ngClass]="fields.conveyance_reference_inbound.control.styles.classes"> 
                        <ng-container *ngIf="fields.conveyance_reference_inbound.invalid">
                          <ng-container *ngFor="let error of fields.conveyance_reference_inbound.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-conveyance_outbound" *ngIf="!fields.conveyance_outbound.hidden" 
                            class="field-container standard {{fields.conveyance_outbound.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.conveyance_outbound.styles.style"
                            [ngClass]="fields.conveyance_outbound.styles.classes">
                        <div class="label-container"
                              title="{{fields.conveyance_outbound.label}}{{fields.conveyance_outbound.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.conveyance_outbound.label}}">{{fields.conveyance_outbound.label}}<span *ngIf="fields.conveyance_outbound.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <TransloadOrders-conveyances_dd_single 
                            data-cy="selector"
                            [type]="fields.conveyance_outbound.control.type"
                            formControlName="conveyance_outbound"
                            (displayTextChange)="fields.conveyance_outbound.control.displayText=$event"
                            [placeholder]="fields.conveyance_outbound.control.placeholder"
                            [styles]="fields.conveyance_outbound.control.styles"
                        >
                        </TransloadOrders-conveyances_dd_single>
                        <ng-container *ngIf="fields.conveyance_outbound.invalid">
                          <ng-container *ngFor="let error of fields.conveyance_outbound.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-conveyance_reference_outbound" *ngIf="!fields.conveyance_reference_outbound.hidden" 
                            class="field-container standard {{fields.conveyance_reference_outbound.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.conveyance_reference_outbound.styles.style"
                            [ngClass]="fields.conveyance_reference_outbound.styles.classes">
                        <div class="label-container"
                              title="{{fields.conveyance_reference_outbound.label}}{{fields.conveyance_reference_outbound.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.conveyance_reference_outbound.label}}">{{fields.conveyance_reference_outbound.label}}<span *ngIf="fields.conveyance_reference_outbound.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="conveyance_reference_outbound"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.conveyance_reference_outbound.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.conveyance_reference_outbound.control.placeholder}}"
                                [ngStyle]="fields.conveyance_reference_outbound.control.styles.style"
                                [ngClass]="fields.conveyance_reference_outbound.control.styles.classes"> 
                        <ng-container *ngIf="fields.conveyance_reference_outbound.invalid">
                          <ng-container *ngFor="let error of fields.conveyance_reference_outbound.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.orderline_total_packaged_amount_widget.hidden" class="widget-container">
            <TransloadOrders-orderline_total_packaged_amount_widget
            #$widgets_orderline_total_packaged_amount_widget 
              [orderId]="$widgets_orderline_total_packaged_amount_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_packaged_amount_widget')"
            >
            </TransloadOrders-orderline_total_packaged_amount_widget>
          </div>
          <div *ngIf="!widgets.orderline_total_received_widget.hidden" class="widget-container">
            <TransloadOrders-orderline_total_received_widget
            #$widgets_orderline_total_received_widget 
              [orderId]="$widgets_orderline_total_received_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_received_widget')"
            >
            </TransloadOrders-orderline_total_received_widget>
          </div>
          <div *ngIf="!widgets.orderline_total_gross_received_widget.hidden" class="widget-container">
            <TransloadOrders-orderline_total_gross_received_widget
            #$widgets_orderline_total_gross_received_widget 
              [orderId]="$widgets_orderline_total_gross_received_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_orderline_total_gross_received_widget')"
            >
            </TransloadOrders-orderline_total_gross_received_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.purchase_order_lines.hidden" class="tab" data-cy="tab-purchase_order_lines">
              <h2 [className]="tabs.purchase_order_lines.active? 'active': ''" (click)="tabs.purchase_order_lines.activate()">{{tabs.purchase_order_lines.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing.hidden" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-transload_order_lines_grid *ngIf="tabs.purchase_order_lines.active"
              #$tabs_purchase_order_lines
              [orderId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_purchase_order_lines')">
              </FootPrintManager-transload_order_lines_grid>
              <FootPrintManager-orderaddresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [orderId]="$tabs_contacts_orderaddresses_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-orderaddresses_grid>
              <ng-container
                *ngIf="tabs.billing.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.billing.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.billing.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.billing.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-accessorial_tasks_grid
                   *ngIf="tabs.billing.tabs.accessorial_tasks.active"
                  #$tabs_billing_accessorial_tasks
                  [projectId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId"
                  [entityStatusId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId"
                  [orderId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId"
                  [warehouseId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_accessorial_tasks')">
                  </FootPrintManager-accessorial_tasks_grid>
                  <FootPrintManager-billing_records_grid
                   *ngIf="tabs.billing.tabs.billing_records.active"
                  #$tabs_billing_billing_records
                  [orderIds]="$tabs_billing_billing_records_billing_records_grid_inParams_orderIds"
                  [includeInvoiced]="$tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced"
                  [shipmentIds]="$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_billing_records')">
                  </FootPrintManager-billing_records_grid>
              </ng-container>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>