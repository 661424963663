import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Locations_location_type_dd_singleComponent } from './Locations.location_type_dd_single.component'
import { Locations_zones_dd_singleComponent } from './Locations.zones_dd_single.component'
import { Locations_storage_categories_dd_singleComponent } from './Locations.storage_categories_dd_single.component'
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_location_type_dd_singleComponent),
    forwardRef(() => Locations_zones_dd_singleComponent),
    forwardRef(() => Locations_storage_categories_dd_singleComponent),
    forwardRef(() => Locations_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-location_creation_form',
  templateUrl: './FootPrintManager.location_creation_form.component.html'
})
export class FootPrintManager_location_creation_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId: number } = { warehouseId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, locationId?: number } = { confirm: null, locationId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, locationId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    parent: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    storage_category: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    is_loose: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    purge_added_inventory: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    eligible_for_allocation: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_primary: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    picking_sequence: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    allocation_sequence: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimension_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_capacity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_capacity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    volume_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    child_footprint: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    child_stack_height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Name', true)
,
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Type', true)
,
    parent: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['parent'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Parent', true)
,
    storage_category: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['storage_category'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Storage category', false)
,
    is_loose: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_loose'] as DatexFormControl, null, false, 'Loose')
, new ControlContainerStyles(null, null), '', false)
,
    purge_added_inventory: new FieldModel(new CheckBoxModel(this.formGroup.controls['purge_added_inventory'] as DatexFormControl, null, false, 'Purge added inventory')
, new ControlContainerStyles(null, null), '', false)
,
    eligible_for_allocation: new FieldModel(new CheckBoxModel(this.formGroup.controls['eligible_for_allocation'] as DatexFormControl, null, false, 'Eligible for allocation')
, new ControlContainerStyles(null, null), '', false)
,
    is_primary: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_primary'] as DatexFormControl, null, false, 'Primary pick')
, new ControlContainerStyles(null, null), '', false)
,
    picking_sequence: new FieldModel(new NumberBoxModel(this.formGroup.controls['picking_sequence'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Picking sequence', false)
,
    allocation_sequence: new FieldModel(new NumberBoxModel(this.formGroup.controls['allocation_sequence'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Allocation sequence', false)
,
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Length', false)
,
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Width', false)
,
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Height', false)
,
    dimension_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimension_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Dimension UOM', false)
,
    weight_capacity: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight_capacity'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Weight capacity', false)
,
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Weight UOM', false)
,
    volume_capacity: new FieldModel(new NumberBoxModel(this.formGroup.controls['volume_capacity'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Volume capacity', false)
,
    volume_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['volume_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Volume UOM', false)
,
    child_footprint: new FieldModel(new NumberBoxModel(this.formGroup.controls['child_footprint'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Pallet high', false)
,
    child_stack_height: new FieldModel(new NumberBoxModel(this.formGroup.controls['child_stack_height'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Pallet tie', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
  newGroup2: new FieldsetModel('Settings', false, true, false),
  outbound_order_settings: new FieldsetModel('Outbound order settings', false, true, false),
  properties: new FieldsetModel('Properties', false, true, false),
};

  //#region fields inParams
  get $fields_parent_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.warehouseId;
    
    return expr;
  }

  get $fields_dimension_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_volume_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 2;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    type: this.fields.type.control.valueChanges
    ,
    parent: this.fields.parent.control.valueChanges
    ,
    storage_category: this.fields.storage_category.control.valueChanges
    ,
    is_loose: this.fields.is_loose.control.valueChanges
    ,
    purge_added_inventory: this.fields.purge_added_inventory.control.valueChanges
    ,
    eligible_for_allocation: this.fields.eligible_for_allocation.control.valueChanges
    ,
    is_primary: this.fields.is_primary.control.valueChanges
    ,
    picking_sequence: this.fields.picking_sequence.control.valueChanges
    ,
    allocation_sequence: this.fields.allocation_sequence.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimension_uom: this.fields.dimension_uom.control.valueChanges
    ,
    weight_capacity: this.fields.weight_capacity.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    volume_capacity: this.fields.volume_capacity.control.valueChanges
    ,
    volume_uom: this.fields.volume_uom.control.valueChanges
    ,
    child_footprint: this.fields.child_footprint.control.valueChanges
    ,
    child_stack_height: this.fields.child_stack_height.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Create location';
  
    const $form = this;
    const $utils = this.utils;

    
    (this.fields.is_loose.control as CheckBoxModel).reset(false);
    (this.fields.purge_added_inventory.control as CheckBoxModel).reset(false);
    (this.fields.eligible_for_allocation.control as CheckBoxModel).reset(true);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .parent
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .storage_category
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_loose
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .purge_added_inventory
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .eligible_for_allocation
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .is_primary
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .picking_sequence
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .allocation_sequence
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimension_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_capacity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_capacity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .child_footprint
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .child_stack_height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_location_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LocationContainer' })).nextId;
  
  $form.vars.nextId = nextId;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_location_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let allRequiredFieldsHaveValue = await validate_has_all_required_fields();
  
  if (allRequiredFieldsHaveValue === false) {
      return;
  }
  
  try {
  
      const location = (await $datasources.Locations.ds_get_locations_by_name_and_warehouseId.get({
          warehouseId: $form.inParams.warehouseId,
          name: $form.fields.name.control.value
      })).result;
      if ($utils.isDefined(location)) {
          throw new Error('Duplicate location found');
      }
  
      const payload = {
          "Id": $form.vars.nextId,
          "WarehouseId": $form.inParams.warehouseId,
          "ParentId": $form.fields.parent.control.value,
          "StatusId": 1,
          "Depth": 1,
          "Path": '/',
          "Name": $form.fields.name.control.value,
          "TypeId": $form.fields.type.control.value,
          "IsLoose": $form.fields.is_loose.control.value == 1 ? true : false,
          "EligibleForAllocation": $form.fields.eligible_for_allocation.control.value == 1 ? true : false,
          "Enabled": true,
          "AllowInventoryIfNotActive": true,
          "GlobalAllocationSequence": $form.fields.allocation_sequence.control.value,
          "GlobalPickSequence": $form.fields.picking_sequence.control.value,
          "AllocationSequence": $form.fields.allocation_sequence.control.value,
          "PickSequence": $form.fields.picking_sequence.control.value,
          "ChildFootprint": $form.fields.child_footprint.control.value,
          "ChildStackHeight": $form.fields.child_stack_height.control.value,
          "Length": $form.fields.length.control.value,
          "Width": $form.fields.width.control.value,
          "Height": $form.fields.height.control.value,
          "DimensionUomId": $form.fields.dimension_uom.control.value,
          "WeightCapacity": $form.fields.weight_capacity.control.value,
          "WeightUomId": $form.fields.weight_uom.control.value,
          "VolumeCapacity": $form.fields.volume_capacity.control.value,
          "VolumeUomId": $form.fields.volume_uom.control.value,
          "ManufacturingLineFlag": false,
          "RestrictToReplenishmentMaterials": false,
          "PurgeAddedInventory": $form.fields.purge_added_inventory.control.value == 1 ? true : false,
          "IsPrimaryPick": $form.fields.is_primary.control.value == 1 ? true : false,
          "SkipCycleCount": false,
          "EligibleForManufacturingFeedback": false,
          "IsManufacturingStagingLocation": false,
          "AllowMoveTaskCreation": false,
          "DisableNonPreferredLocationAlertCreation": false,
          "SkipValidationRules": true,
          "BreakInventoryToBasePackaging": false,
          "ConfirmInventoryDeletion": false,
          "EligibleForScaleTicket": false,
          "SkipAfterPickingCountBack": false,
          "AutoSelectCountOnCountBack": false,
          "MobileForceUserCountBack": false,
          "IsPackageVerification": false,
          "IsDynamicPrimaryPick": false,
          "IsUsedForReturns": false,
          "StorageCategoryId": $form.fields.storage_category.control.value
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'LocationContainers', entity: payload });
  
      if ($form.fields.is_loose.control.value) {
          // Create loose license plate
          const nextLicensePlateId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LicensePlate' })).nextId;
          const licensePlatePayload = {
              "Id": nextLicensePlateId,
              "WarehouseId": $form.inParams.warehouseId,
              "LocationId": $form.vars.nextId,
              "TypeId": 3,
              "StatusId": 1,
              "LookupCode": $form.fields.name.control.value,
              "Archived": false
          };
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'LicensePlates', entity: licensePlatePayload });
      }
      $form.outParams.confirm = true;
      $form.outParams.locationId = $form.vars.nextId;
      $form.close();
  
  }
  catch (error) {
      $shell.Locations.openErrorDialog('Error saving location', error.message);
  }
  
  
  
  /****************************************
   * FUNCTIONS
  *****************************************/
  async function validate_has_all_required_fields(): Promise<boolean> {
      // Check required fields
      let missing_fields: string[] = []
  
      for (let key in $form.fields) {
          if ($form.fields[key].required && !$utils.isDefined($form.fields[key].control.value)) {
              missing_fields.push($form.fields[key].label);
          }
      }
  
      await $shell.Locations.openErrorDialog('Error saving location', `Missing required field${missing_fields.length > 1 ? 's' : ''}: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: missing_fields })).formattedValue}`);
  
      return !$utils.isDefined(missing_fields);
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_location_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  //#endregion private flows
}
