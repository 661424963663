import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepConfig } from './components/wizard.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_pack_verification_verify_container_editorComponent } from './FootPrintManager.pack_verification_verify_container_editor.component';
import { FootPrintManager_pack_verification_shipping_container_properties_editorComponent } from './FootPrintManager.pack_verification_shipping_container_properties_editor.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_pack_verification_completion_editorComponent } from './FootPrintManager.pack_verification_completion_editor.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,

    forwardRef(() => FootPrintManager_pack_verification_verify_container_editorComponent),
    forwardRef(() => FootPrintManager_pack_verification_shipping_container_properties_editorComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_pack_verification_completion_editorComponent),
  ],
  selector: 'FootPrintManager-pack_verification_wizard',
  templateUrl: './FootPrintManager.pack_verification_wizard.component.html'
})
export class FootPrintManager_pack_verification_wizardComponent implements OnInit, OnChanges {
  _stepConfigs: StepConfig[];

  inParams: { shippingContainerId: number, startVerificationTaskId?: number, targetLocationId?: number, isBlind?: boolean } = { shippingContainerId: null, startVerificationTaskId: null, targetLocationId: null, isBlind: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  @Input('startVerificationTaskId') set $inParams_startVerificationTaskId(v: number) {
    this.inParams.startVerificationTaskId = v;
  }
  get $inParams_startVerificationTaskId(): number {
    return this.inParams.startVerificationTaskId;
  }
  @Input('targetLocationId') set $inParams_targetLocationId(v: number) {
    this.inParams.targetLocationId = v;
  }
  get $inParams_targetLocationId(): number {
    return this.inParams.targetLocationId;
  }
  @Input('isBlind') set $inParams_isBlind(v: boolean) {
    this.inParams.isBlind = v;
  }
  get $inParams_isBlind(): boolean {
    return this.inParams.isBlind;
  }
  //#endregion Inputs

  //#region Outputs
  @Output() 
  $commandsTmpRef = new EventEmitter<TemplateRef<any>>();
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { projectId?: number, orderId?: number, warehouseId?: number, instructions?: { entity?: string, code?: string, instruction?: string, isCompleted?: boolean, id?: number }[], properties?: { containerType?: string, dimensions?: string, weight?: string, actualWeight?: number }, canAddAccessorials?: boolean, isContentValidated?: boolean, isPropertiesValidated?: boolean, scannedValues?: { scannedValue?: string, materialId?: number, serialNumberId?: number, isVerified?: boolean, reasons?: string[], lotId?: number, packagingId?: number }[], isFailed?: boolean, processQueue?: string[], configurations?: { requireCompleteAllInstructions?: boolean, requireContainerType?: boolean, weightDiscrepancyThresholdPercent?: number } } = { };
  //#endregion
  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Pack verification';
  
    const $wizard = this;
    const $utils = this.utils;

    await this.on_init();

    this.initialized = true;
  }

  steps: {
    step1_verify_content?: {
      outParams?: { scannedValues?: { scannedValue?: string, materialId?: number, serialNumberId?: number, isVerified?: boolean, reasons?: string[], lotId?: number, packagingId?: number }[] }
    }
    step4_set_container_properties?: {
      outParams?: {  }
    }
    step6_accessorials?: {
      outParams?: {  }
    }
    step7_complete_verification?: {
      outParams?: {  }
    }
  } = { };

  stepsResultFunc(result: any) {
    this.steps = result;
  }
  
  getStepConfigs(): StepConfig[] {
    if (!this._stepConfigs) {
      const $wizard = this;
      const $utils = this.utils;

      this._stepConfigs = [
      {
          id: 'step1_verify_content',
          title: 'Verify content',
          component: FootPrintManager_pack_verification_verify_container_editorComponent,
          inParamsFunc: ($index?: number) => {
            return {
              shippingContainerId: $wizard.inParams.shippingContainerId,
              startVerificationTaskId: $wizard.inParams.startVerificationTaskId,
              isBlind: $wizard.inParams.isBlind,
              targetLocationId: $wizard.inParams.targetLocationId,
              isReadOnly: $wizard.vars.isContentValidated,
            }
          },
          next: 'step4_set_container_properties',
      },
      {
          id: 'step4_set_container_properties',
          title: 'Set container properties',
          component: FootPrintManager_pack_verification_shipping_container_properties_editorComponent,
          inParamsFunc: ($index?: number) => {
            return {
              shippingContainerId: $wizard.inParams.shippingContainerId,
              startVerificationTaskId: $wizard.inParams.startVerificationTaskId,
            }
          },
          nextConditionFunc: ($index?: number) => {
            return $wizard.vars.canAddAccessorials;
          },
          next: 'step6_accessorials',
          nextAlt: 'step7_complete_verification',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return ($wizard.vars.configurations.requireCompleteAllInstructions && $utils.isDefined($wizard.vars.instructions.find(i => !i.isCompleted))) || ($wizard.vars.configurations.requireContainerType && !$utils.isDefined($wizard.vars.properties.containerType));
          },
      },
      {
          id: 'step6_accessorials',
          title: 'Add accessorials',
          component: FootPrintManager_accessorial_tasks_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              workOrderId: null,
              projectId: $wizard.vars.projectId,
              entityStatusId: 1,
              orderId: $wizard.vars.orderId,
              warehouseId: $wizard.vars.warehouseId,
              shippingContainerId: $wizard.inParams.shippingContainerId,
              showAllOpCodes: null,
              shipmentId: null,
            }
          },
          next: 'step7_complete_verification',
      },
      {
          id: 'step7_complete_verification',
          title: 'Complete verification',
          component: FootPrintManager_pack_verification_completion_editorComponent,
          inParamsFunc: ($index?: number) => {
            return {
              shippingContainerId: $wizard.inParams.shippingContainerId,
              startVerificationTaskId: $wizard.inParams.startVerificationTaskId,
              instructions: $wizard.vars.instructions.map(i => ({entity: i.entity, code: i.code, instruction: i.instruction, isCompleted: i.isCompleted})),
              scannedValues: $wizard.vars.scannedValues.map(v => ({ scannedValue: v.scannedValue, lotId: v.lotId, packagingId: v.packagingId })),
              properties: {containerType: $wizard.vars.properties.containerType, dimensions: $wizard.vars.properties.dimensions, weight: $wizard.vars.properties.weight},
              targetLocationId: $wizard.inParams.targetLocationId,
            }
          },
          nextButtonLabel: 'Complete',
      },
      ];
    }

    return this._stepConfigs;
  }

  async finish() {
    const $wizard = this;
    const $utils = this.utils;

    await this.on_finish();

    this.close();
  }

  close() {
    this.$finish.emit();
  }

  commandsTmpRefChange(tmp: any) {
    this.$commandsTmpRef.emit(tmp);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $wizard: FootPrintManager_pack_verification_wizardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $wizard.vars.isFailed = false;
  $wizard.vars.instructions = [];
  $wizard.vars.scannedValues = [];
  $wizard.vars.processQueue = [];
  
  await get_configurations();
  
  $wizard.inParams.isBlind = $wizard.inParams.isBlind ?? true;
  try {
  
  
      if (await $operations.FootPrintManager.Disable_Accessorials_ChargeEntry.isAuthorized()) {
          $wizard.vars.canAddAccessorials = false;
      } else {
          $wizard.vars.canAddAccessorials = true;
      }
  
      // Validate target location
      if (!$utils.isDefined($wizard.inParams.targetLocationId)) {
          // Get shipping container's current location
          $wizard.inParams.targetLocationId = (await $datasources.PackVerification.ds_get_shippingcontainer_by_shippingContainerId.get({ shippingContainerId: $wizard.inParams.shippingContainerId })).result.LicensePlates[0]?.LocationId;
      }
  
      let locationValidationResult = (await $flows.PackVerification.is_pack_verification_location_valid_flow({ shippingContainerId: $wizard.inParams.shippingContainerId, locationId: $wizard.inParams.targetLocationId }));
  
      if ($utils.isDefined(locationValidationResult.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: locationValidationResult.reasons })).formattedValue);
      }
  
      $wizard.vars.properties = { containerType: '', dimensions: '', weight: '' };
  
      // Get shipping container details
      let shippingContainer = (await $datasources.PackVerification.ds_get_shippingcontainer_by_shippingContainerId.get({ shippingContainerId: $wizard.inParams.shippingContainerId })).result;
  
      $wizard.vars.orderId = shippingContainer.Shipment?.OrderLookups[0]?.Order?.Id;
      $wizard.vars.projectId = shippingContainer.Shipment?.OrderLookups[0]?.Order?.Project?.Id;
      $wizard.vars.warehouseId = shippingContainer.Shipment?.ActualWarehouseId;
      $wizard.vars.isContentValidated = false;
      $wizard.vars.isPropertiesValidated = false;
  
      // Get startVerificationTask
      let result = (await $flows.PackVerification.begin_pack_verification_flow({
          shippingContainerId: shippingContainer.Id
      }));
  
      if ($utils.isDefined(result.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue)
      }
  
      $wizard.inParams.startVerificationTaskId = result.startEndVerificationTaskId;
  
      add_events();
  
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error initializing wizard', targetError.message);
  
      // @ts-ignore
      $wizard.close();
  }
  
  
  /************************************************
   * EVENTS
  *************************************************/
  async function add_events() {
      await new Promise(result => setTimeout(result, 100));
  
      // Get element to attach to 
      // const elements = document.querySelectorAll('h1.modal-title');
      // const element = Array.from(elements).find(e => e.textContent.trim().toUpperCase() === "PACK VERIFICATION") as HTMLElement;
      const element = document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0];
  
      if (element == null) {
          throw new Error(`Failed to identify the HTML element for pack verification wizard`);
      }
  
      // 'on' events to detect different stages of the wizard
      element.addEventListener('on_pack_verification_verify_container_editor_initialized', ((event: CustomEvent) => { }) as EventListener, { once: true });
  
      element.addEventListener('on_pack_verification_shipping_container_properties_editor_initialized', ((event: CustomEvent) => {
          if (!$wizard.vars.isContentValidated) { validateContents(); }
      }) as EventListener, { once: true });
  
      element.addEventListener('on_accessorial_tasks_grid_initialized', ((event: CustomEvent) => {
          if (!$wizard.vars.isContentValidated) { validateContents(); }
          if (!$wizard.vars.isPropertiesValidated) { validateProperties(); }
      }) as EventListener, { once: true });
  
      element.addEventListener('on_pack_verification_completion_editor_initialized', ((event: CustomEvent) => {
          if (!$wizard.vars.isContentValidated) { validateContents(); }
          if (!$wizard.vars.isPropertiesValidated) { validateProperties(); }
      }) as EventListener, { once: true });
  
      // 'handle' events to perform common actions that may be invoked
      element.addEventListener('handle_scanned_values_changed', ((event: CustomEvent<{ scannedValue: string, materialId: number, serialNumberId: number, isVerified: boolean, reasons: string[], lotId: number, packagingId: number }[]>) => {
          $wizard.vars.scannedValues = event.detail;
      }) as EventListener);
  
      element.addEventListener('handle_container_instructions_changed', ((event: CustomEvent<{ entity: string, code: string, instruction: string, isCompleted: boolean, id: number }[]>) => {
          $wizard.vars.instructions = event.detail;
      }) as EventListener);
  
      element.addEventListener('handle_container_properties_changed', ((event: CustomEvent<{ containerType: string, dimensions: string, weight: string, actualWeight: number }>) => {
          $wizard.vars.properties = event.detail;
      }) as EventListener);
  
      element.addEventListener('handleCloseWizard', ((event: CustomEvent) => {
          // @ts-ignore
          $wizard.close();
      }) as EventListener, { once: true });
  }
  
  /************************************************
   * FUNCTIONS
  *************************************************/
  async function validateContents() {
      $wizard.vars.isContentValidated = true;
  
      let id = $utils.createGuid();
      $wizard.vars.processQueue.push(id);
  
      await new Promise(resolve => setTimeout(resolve, 100));
  
      if (id !== $wizard.vars.processQueue[0]) {
          $wizard.vars.processQueue.splice($wizard.vars.processQueue.indexOf(id), 1);
          return;
      }
      $wizard.vars.processQueue.splice($wizard.vars.processQueue.indexOf(id), 1);
  
  
      let result = (await $flows.PackVerification.validate_shipping_container_contents_flow({ startPackVerificationTaskId: $wizard.inParams.startVerificationTaskId, canSubstituteSerials: true }));
  
      if ($wizard.vars.scannedValues.find(v => !v.isVerified)) {
          failValidation('ValidateContents', ['There were invalid scans']);
      }
      else if (!result.isValid) {
          failValidation('ValidateContents', result.reasons);
      }
      else {
          window.dispatchEvent(new CustomEvent('handlepack_verification_verify_container_editorValidated'));
      }
  }
  
  async function validateProperties() {
      $wizard.vars.isPropertiesValidated = true;
  
      let id = $utils.createGuid();
      $wizard.vars.processQueue.push(id);
  
      await new Promise(resolve => setTimeout(resolve, 100));
  
      if (id !== $wizard.vars.processQueue[0]) {
          $wizard.vars.processQueue.splice($wizard.vars.processQueue.indexOf(id), 1);
          return;
      }
      $wizard.vars.processQueue.splice($wizard.vars.processQueue.indexOf(id), 1);
  
      let requireWeight = true;
      let weightToValidate = $wizard.vars.properties.actualWeight;
  
      if (!$wizard.inParams.isBlind) {
          requireWeight = false;
          weightToValidate = null;
      }
  
      let result = (await $flows.PackVerification.validate_shipping_container_properties_flow({
          startPackVerificationTaskId: $wizard.inParams.startVerificationTaskId,
          additionalInputs: {
              isActualWeightRequired: requireWeight,
              actualWeight: weightToValidate
          }
      }));
  
      if (!result.isValid) {
          failValidation('ValidateProperties', result.reasons);
      }
      else {
          window.dispatchEvent(new CustomEvent('handlepack_verification_shipping_container_properties_editorValidated'));
      }
  
  }
  
  async function failValidation(context: string, reasons: string[]) {
      //await stagger();
  
      if (!$wizard.vars.isFailed) {
          $wizard.vars.isFailed = true;
  
          // Remove duplicates
          reasons = [... new Set(reasons)];
  
          // Get reason code 
          let reasonCodeId = (await $flows.PackVerification.get_failure_reasoncode_flow({ inputs: { context: context, taskId: $wizard.inParams.startVerificationTaskId } })).reasonCodeId;
  
          // Get target location to move shipping container to
          let targetLocationId = (await $flows.PackVerification.get_failure_location_flow({
              inputs: {
                  context: context,
                  defaultLocationId: $wizard.inParams.targetLocationId,
                  taskId: $wizard.inParams.startVerificationTaskId
              }
          })).targetLocationId;
  
          // Fail validation, close wizard
          $flows.PackVerification.fail_pack_verification_flow({
              shippingContainerId: $wizard.inParams.shippingContainerId,
              startPackVerificationTaskId: $wizard.inParams.startVerificationTaskId,
              targetLocationId: targetLocationId,
              reasonCodeId: reasonCodeId
          });
  
          await $shell.FootPrintManager.openInfoDialog('Pack verification failed', `Verification failed because ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}.`);
  
          // @ts-ignore
          $wizard.close();
      }
  }
  
  async function stagger() {
      await new Promise(resolve => setTimeout(resolve, Math.random() * 100));
  }
  
  async function get_configurations() {
      const CONFIGS = (await $flows.PackVerification.get_configurations_flow({})).configurations;
  
  
      $wizard.vars.configurations = {};
  
      if ($utils.isDefined(CONFIGS)) {
          // RequireCompleteAllInstructions
          const requireCompleteAllInstructionsString = CONFIGS.find(c => c.name.trim().toUpperCase() === 'REQUIRECOMPLETEALLINSTRUCTIONS')?.value?.toLowerCase();
          if ($utils.isDefinedTrimmed(requireCompleteAllInstructionsString)) {
              $wizard.vars.configurations.requireCompleteAllInstructions = JSON.parse(requireCompleteAllInstructionsString);
          } else { $wizard.vars.configurations.requireCompleteAllInstructions = false; }
  
          // RequireContainerType
          const requireContainerTypeString = CONFIGS.find(c => c.name.trim().toUpperCase() === 'REQUIRECONTAINERTYPE')?.value?.toLowerCase();
          if ($utils.isDefinedTrimmed(requireContainerTypeString)) {
              $wizard.vars.configurations.requireContainerType = JSON.parse(requireContainerTypeString);
          } else { $wizard.vars.configurations.requireContainerType = false; }
  
          // WeightVarianceThresholdPercent
          const weightThresholdPercentString = CONFIGS.find(c => c.name.trim().toUpperCase() === 'WEIGHTVARIANCETHRESHOLDPERCENT')?.value;
          if ($utils.isDefinedTrimmed(weightThresholdPercentString) && !isNaN(Number(weightThresholdPercentString))) {
              $wizard.vars.configurations.weightDiscrepancyThresholdPercent = Number(weightThresholdPercentString);
          }
      }
  }
  }
  on_finish(event = null) {
    return this.on_finishInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_finishInternal(
    $wizard: FootPrintManager_pack_verification_wizardComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let reasons = (await $flows.PackVerification.complete_pack_verification_flow({
      startPackVerificationTaskId: $wizard.inParams.startVerificationTaskId,
      targetLocationId: $wizard.inParams.targetLocationId
  })).reasons;
  
  if ($utils.isDefined(reasons)) {
      let message = (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue;
      await $shell.FootPrintManager.openErrorDialog('Error completing pack verification', message);
      throw new Error(message);
  }
  }
  //#endregion private flows
}
