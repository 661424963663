import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelLocationImport_excel_location_import_library_homeComponent } from './ExcelLocationImport.excel_location_import_library_home.component';
import { ExcelLocationImport_excel_location_import_gridComponent } from './ExcelLocationImport.excel_location_import_grid.component';
import { ExcelLocationImport_location_type_dd_singleComponent } from './ExcelLocationImport.location_type_dd_single.component';
import { ExcelLocationImport_materialstatuses_dd_singleComponent } from './ExcelLocationImport.materialstatuses_dd_single.component';
import { ExcelLocationImport_measurement_units_dd_singleComponent } from './ExcelLocationImport.measurement_units_dd_single.component';
import { ExcelLocationImport_mixing_rule_dd_singleComponent } from './ExcelLocationImport.mixing_rule_dd_single.component';
import { ExcelLocationImport_storage_category_dd_singleComponent } from './ExcelLocationImport.storage_category_dd_single.component';
import { ExcelLocationImport_warehouses_dd_singleComponent } from './ExcelLocationImport.warehouses_dd_single.component';
import { ExcelLocationImport_yn_dd_singleComponent } from './ExcelLocationImport.yn_dd_single.component';
import { ExcelLocationImport_zones_dd_singleComponent } from './ExcelLocationImport.zones_dd_single.component';
import { ExcelLocationImport_location_type_dd_multiComponent } from './ExcelLocationImport.location_type_dd_multi.component';
import { ExcelLocationImport_materialstatuses_dd_multiComponent } from './ExcelLocationImport.materialstatuses_dd_multi.component';
import { ExcelLocationImport_measurement_units_dd_multiComponent } from './ExcelLocationImport.measurement_units_dd_multi.component';
import { ExcelLocationImport_mixing_rule_dd_multiComponent } from './ExcelLocationImport.mixing_rule_dd_multi.component';
import { ExcelLocationImport_storage_category_dd_multiComponent } from './ExcelLocationImport.storage_category_dd_multi.component';
import { ExcelLocationImport_warehouses_dd_multiComponent } from './ExcelLocationImport.warehouses_dd_multi.component';
import { ExcelLocationImport_yn_dd_multiComponent } from './ExcelLocationImport.yn_dd_multi.component';
import { ExcelLocationImport_zones_dd_multiComponent } from './ExcelLocationImport.zones_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class ExcelLocationImport_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public ExcelLocationImport: ExcelLocationImport_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openexcel_location_import_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'ExcelLocationImport_excel_location_import_library_home',
        component: ExcelLocationImport_excel_location_import_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_location_import_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelLocationImport_excel_location_import_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openexcel_location_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Location Import',
        referenceName: 'ExcelLocationImport_excel_location_import_grid',
        component: ExcelLocationImport_excel_location_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_location_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelLocationImport_excel_location_import_gridComponent,
      'Location Import',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'ExcelLocationImport_excel_location_import_library_home') {
      const title = 'Home';
      const component = ExcelLocationImport_excel_location_import_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_excel_location_import_grid') {
      const title = 'Location Import';
      const component = ExcelLocationImport_excel_location_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_location_type_dd_single') {
      const title = 'Location Type Dropdown';
      const component = ExcelLocationImport_location_type_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_materialstatuses_dd_single') {
      const title = 'Material Statuses Dropdown.';
      const component = ExcelLocationImport_materialstatuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_measurement_units_dd_single') {
      const title = 'Measurement Units Dropdown';
      const component = ExcelLocationImport_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_mixing_rule_dd_single') {
      const title = 'mixing_rule_dd';
      const component = ExcelLocationImport_mixing_rule_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_storage_category_dd_single') {
      const title = 'storage_category_dd';
      const component = ExcelLocationImport_storage_category_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_warehouses_dd_single') {
      const title = 'Warehouses Dropdown';
      const component = ExcelLocationImport_warehouses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_yn_dd_single') {
      const title = 'Yes No Dropdown';
      const component = ExcelLocationImport_yn_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_zones_dd_single') {
      const title = 'Zones Dropdown';
      const component = ExcelLocationImport_zones_dd_singleComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_location_type_dd_multi') {
      const title = 'Location Type Dropdown';
      const component = ExcelLocationImport_location_type_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_materialstatuses_dd_multi') {
      const title = 'Material Statuses Dropdown.';
      const component = ExcelLocationImport_materialstatuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_measurement_units_dd_multi') {
      const title = 'Measurement Units Dropdown';
      const component = ExcelLocationImport_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_mixing_rule_dd_multi') {
      const title = 'mixing_rule_dd';
      const component = ExcelLocationImport_mixing_rule_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_storage_category_dd_multi') {
      const title = 'storage_category_dd';
      const component = ExcelLocationImport_storage_category_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_warehouses_dd_multi') {
      const title = 'Warehouses Dropdown';
      const component = ExcelLocationImport_warehouses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_yn_dd_multi') {
      const title = 'Yes No Dropdown';
      const component = ExcelLocationImport_yn_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelLocationImport_zones_dd_multi') {
      const title = 'Zones Dropdown';
      const component = ExcelLocationImport_zones_dd_multiComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
