import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_load_slip_reportServiceInParams {
  orderId: number}

interface Iapp_custom_load_slip_reportServiceData {
  Lines?: { result?: { OrderId?: number, LineNumber?: number, LotId?: number, PackagedAmount?: number, Order?: { LookupCode?: string, Notes?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string } }, Shipment?: { ConveyenceReference?: string, LookupCode?: string, PickupDate?: string, ReferenceNumber?: string }, Material?: { Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, LotLocation?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, LicensePlate?: { Id?: number, Location?: { Id?: number, Name?: string } } }, LotAvgWeight?: { AvgNetWeight?: number } }[] }
  ShipTo?: { result?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }
  ShipAs?: { result?: { Id?: number, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  Warehouse?: { result?: { Id?: number, PreferredWarehouse?: { Id?: number, WarehousesContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }
}

@Injectable({ providedIn: 'root' })
export class app_custom_load_slip_reportService extends ReportBaseService<Iapp_custom_load_slip_reportServiceInParams, Iapp_custom_load_slip_reportServiceData> {

  protected reportReferenceName = 'custom_load_slip_report';
  protected appReferenceName = 'app';

  constructor(
    utils: UtilsService,
    private datasources: app_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_load_slip_reportServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_load_slip_reportServiceInParams): Promise<Iapp_custom_load_slip_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: Iapp_custom_load_slip_reportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_load_slip_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      const dsData = await this.datasources.SalesOrders.ds_load_slip_order_lines_report.get(dsParams);
      
      data.Lines = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      const dsData = await this.datasources.SalesOrders.ds_get_order_ship_to_account_vs_order_address.get(dsParams);
      
      data.ShipTo = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      const dsData = await this.datasources.SalesOrders.ds_get_order_owner_contact_top1.get(dsParams);
      
      data.ShipAs = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      const dsData = await this.datasources.SalesOrders.ds_get_order_warehouse_contact_top1.get(dsParams);
      
      data.Warehouse = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
