import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';

import { app_custom_bill_of_lading_reportService } from './app.report.index';
import { app_custom_invoice_reportService } from './app.report.index';
import { app_custom_load_slip_reportService } from './app.report.index';
import { app_custom_pick_slip_reportService } from './app.report.index';
import { app_custom_receiving_reportService } from './app.report.index';
import { app_custom_unloading_tally_reportService } from './app.report.index';

@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
  }
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Utilities: Utilities_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Invoices: Invoices_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_bill_of_lading_report: app_custom_bill_of_lading_reportService;
  public get custom_bill_of_lading_report(): app_custom_bill_of_lading_reportService {
    if(!this._custom_bill_of_lading_report) {
      this._custom_bill_of_lading_report = this.injector.get(app_custom_bill_of_lading_reportService);
    }
    return this._custom_bill_of_lading_report;
  }
  private _custom_invoice_report: app_custom_invoice_reportService;
  public get custom_invoice_report(): app_custom_invoice_reportService {
    if(!this._custom_invoice_report) {
      this._custom_invoice_report = this.injector.get(app_custom_invoice_reportService);
    }
    return this._custom_invoice_report;
  }
  private _custom_load_slip_report: app_custom_load_slip_reportService;
  public get custom_load_slip_report(): app_custom_load_slip_reportService {
    if(!this._custom_load_slip_report) {
      this._custom_load_slip_report = this.injector.get(app_custom_load_slip_reportService);
    }
    return this._custom_load_slip_report;
  }
  private _custom_pick_slip_report: app_custom_pick_slip_reportService;
  public get custom_pick_slip_report(): app_custom_pick_slip_reportService {
    if(!this._custom_pick_slip_report) {
      this._custom_pick_slip_report = this.injector.get(app_custom_pick_slip_reportService);
    }
    return this._custom_pick_slip_report;
  }
  private _custom_receiving_report: app_custom_receiving_reportService;
  public get custom_receiving_report(): app_custom_receiving_reportService {
    if(!this._custom_receiving_report) {
      this._custom_receiving_report = this.injector.get(app_custom_receiving_reportService);
    }
    return this._custom_receiving_report;
  }
  private _custom_unloading_tally_report: app_custom_unloading_tally_reportService;
  public get custom_unloading_tally_report(): app_custom_unloading_tally_reportService {
    if(!this._custom_unloading_tally_report) {
      this._custom_unloading_tally_report = this.injector.get(app_custom_unloading_tally_reportService);
    }
    return this._custom_unloading_tally_report;
  }
}

