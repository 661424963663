import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_receiving_header_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string, PickupDate?: string, SealId?: string, TrailerId?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string } } }[], Project?: { LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string }, WarehouseContact?: { Id?: number, PreferredWarehouse?: { Id?: number, WarehousesContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }, OwnerContact?: { Id?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, AccountContact?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, Email?: string }, OwnerContactLine1?: string, OwnerContactCity?: string, OwnerContactState?: string, OwnerContactPostalCode?: string } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_receiving_header_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
