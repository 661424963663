<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container double {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container double {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Lots-lot_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                        >
                        </Lots-lot_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reason_codes" *ngIf="!fields.reason_codes.hidden" 
                            class="field-container double {{fields.reason_codes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reason_codes.styles.style"
                            [ngClass]="fields.reason_codes.styles.classes">
                        <div class="label-container"
                              title="{{fields.reason_codes.label}}{{fields.reason_codes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reason_codes.label}}">{{fields.reason_codes.label}}<span *ngIf="fields.reason_codes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Lots-reason_codes_multi 
                            data-cy="selector-multi"
                            [type]="fields.reason_codes.control.type"
                            formControlName="reason_codes"
                            (displayTextChange)="fields.reason_codes.control.displayText=$event"
                            [placeholder]="fields.reason_codes.control.placeholder"
                            [styles]="fields.reason_codes.control.styles"
                        >
                        </Lots-reason_codes_multi>
                        <ng-container *ngIf="fields.reason_codes.invalid">
                          <ng-container *ngFor="let error of fields.reason_codes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container double {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                              title="{{fields.description.label}}{{fields.description.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.description.label}}">{{fields.description.label}}<span *ngIf="fields.description.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendorlot" *ngIf="!fields.vendorlot.hidden" 
                            class="field-container double {{fields.vendorlot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendorlot.styles.style"
                            [ngClass]="fields.vendorlot.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendorlot.label}}{{fields.vendorlot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendorlot.label}}">{{fields.vendorlot.label}}<span *ngIf="fields.vendorlot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vendorlot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendorlot.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendorlot.control.placeholder}}"
                                [ngStyle]="fields.vendorlot.control.styles.style"
                                [ngClass]="fields.vendorlot.control.styles.classes"> 
                        <ng-container *ngIf="fields.vendorlot.invalid">
                          <ng-container *ngFor="let error of fields.vendorlot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-manufacturing_date" *ngIf="!fields.manufacturing_date.hidden" 
                            class="field-container double {{fields.manufacturing_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.manufacturing_date.styles.style"
                            [ngClass]="fields.manufacturing_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.manufacturing_date.label}}{{fields.manufacturing_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.manufacturing_date.label}}">{{fields.manufacturing_date.label}}<span *ngIf="fields.manufacturing_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="manufacturing_date"
                            [format]="fields.manufacturing_date.control.format"
                            [mode]="fields.manufacturing_date.control.mode"
                            [ngStyle]="fields.manufacturing_date.control.styles.style"
                            [ngClass]="fields.manufacturing_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.manufacturing_date.invalid">
                          <ng-container *ngFor="let error of fields.manufacturing_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expiration_date" *ngIf="!fields.expiration_date.hidden" 
                            class="field-container double {{fields.expiration_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expiration_date.styles.style"
                            [ngClass]="fields.expiration_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.expiration_date.label}}{{fields.expiration_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.expiration_date.label}}">{{fields.expiration_date.label}}<span *ngIf="fields.expiration_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expiration_date"
                            [format]="fields.expiration_date.control.format"
                            [mode]="fields.expiration_date.control.mode"
                            [ngStyle]="fields.expiration_date.control.styles.style"
                            [ngClass]="fields.expiration_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.expiration_date.invalid">
                          <ng-container *ngFor="let error of fields.expiration_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendorlot_vat" *ngIf="!fields.vendorlot_vat.hidden" 
                            class="field-container double {{fields.vendorlot_vat.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendorlot_vat.styles.style"
                            [ngClass]="fields.vendorlot_vat.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendorlot_vat.label}}{{fields.vendorlot_vat.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendorlot_vat.label}}">{{fields.vendorlot_vat.label}}<span *ngIf="fields.vendorlot_vat.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vendorlot_vat"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendorlot_vat.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendorlot_vat.control.placeholder}}"
                                [ngStyle]="fields.vendorlot_vat.control.styles.style"
                                [ngClass]="fields.vendorlot_vat.control.styles.classes"> 
                        <ng-container *ngIf="fields.vendorlot_vat.invalid">
                          <ng-container *ngFor="let error of fields.vendorlot_vat.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>